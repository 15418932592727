import React from "react";
import { Helmet } from "react-helmet";

const SchemaLocalBusiness = ({ settings, seoUrl }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "${settings?.BusinessName || null}",
          "image": "${settings?.defaultImage || null}",
          "@id": "${settings.siteUrl}",
          "url": "${seoUrl}",
          "telephone": "${settings?.BusinessPhoneNumber || null}",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "${settings?.BusinessAddress || null}",
            "addressLocality": "${settings?.BusinessCity || null}",
            "postalCode": "${settings?.BusinessPostalCode || null} ",
            "addressCountry": "${settings?.BusinessCountry || null}"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Thursday",
              "Tuesday",
              "Wednesday",
              "Friday"
            ],
            "opens": "09:00",
            "closes": "17:00"
          },
          "department": {
            "@type": "ProfessionalService",
            "name": "${settings.title}",
            "image": "${settings.defaultImage}",
            "telephone": "${settings?.BusinessPhoneNumber}"
          }
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaLocalBusiness;
