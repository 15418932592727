import React from "react";
import { Helmet } from "react-helmet";

const SchemaService = ({ settings, seoUrl }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/",
          "@type": "Service",
          "serviceType": "Event Organization",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Eventure"
          }
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaService;
