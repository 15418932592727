import { Link } from "gatsby";
import React from "react";
import { basePathResolver } from "../utilities/basePathResolver";
import { ArrowInCircle } from "../common/Icons";

const CircleArrowBox = ({ background, label, slug }) => {
  return (
    <Link
      to={basePathResolver(slug)}
      className={`box circle-arrow-box white bg--${background}`}
    >
      <ArrowInCircle />
      <h4
        className="label-container"
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </Link>
  );
};

export default CircleArrowBox;
