import React from "react";
import CustomImage from "../common/CustomImage";
import CircleArrowBox from "../components/CircleArrowBox";
import RichText from "../components/RichText";
import { StaticImage } from "gatsby-plugin-image";

const TitleAndTwoBoxes = ({ block, locale }) => {
  const {
    title,
    BackgroundImage,
    BackgroundPosition,
    Box1BackgroundColor,
    Box1Label,
    Box1Slug,
    Box2BackgroundColor,
    Box2Label,
    Box2Slug,
  } = block;

  return (
    <section
      className={`title-with-two-boxes bg-position-${
        BackgroundPosition?.Position || "MiddleCenter"
      }`}
    >
      <StaticImage
        src="../images/bg-grey-pattern.jpg"
        alt="bg"
        className="bg"
      />
      <CustomImage image={BackgroundImage} isBackground className="hand" />
      <div className="inner">
        <div className="content">
          <RichText className="white" richText={title} />
          <div className="boxes">
            <CircleArrowBox
              background={Box1BackgroundColor}
              label={Box1Label}
              slug={Box1Slug}
            />
            <CircleArrowBox
              background={Box2BackgroundColor}
              label={Box2Label}
              slug={Box2Slug}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleAndTwoBoxes;
