import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import HpHeroSection from "../sections/HpHeroSection";
import Why from "../sections/Why";
import GridBoxes from "../sections/GridBoxes";
import Hexagons from "../sections/Hexagons";
import WavesBg from "../sections/WavesBg";
import BannerCTA from "../sections/BannerCTA";
import Testimonials from "../sections/Testimonials";
import Partners from "../sections/Partners";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Signup from "../forms/Signup";
import Signin from "../forms/Signin";
import { useAuth } from "../context/AuthContext";
import Seo from "../components/meta/Seo";
import TitleAndTwoBoxes from "../sections/TitleAndTwoBoxes";

const HomepageTemplate = ({ data, pageContext }) => {
  const { settings, page, allTestimonials } = data;
  const { Blocks, locale } = page;

  return (
    <Layout settingsData={settings} pageData={page}>
      <Seo page={page} curPage="Homepage" pageType={"page"} />
      <Helmet htmlAttributes={{ lang: pageContext.lang.split("-")[0] }}>
        <title>ESG Rating</title>
      </Helmet>
      {Blocks?.map((block) => {
        // eslint-disable-next-line default-case
        switch (block.strapi_component) {
          case "sections.title-video-bg":
            return (
              <HpHeroSection
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.slides":
            return (
              <Why block={block} key={block.strapi_component} locale={locale} />
            );
          case "sections.title-and-two-boxes":
            return (
              <TitleAndTwoBoxes
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.hexagons":
            return (
              <Hexagons
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.waves":
            return (
              <WavesBg
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.banner":
            return (
              <BannerCTA
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.testimonials":
            return (
              <Testimonials
                allTestimonials={allTestimonials}
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          case "sections.trusted-partner":
            return (
              <Partners
                block={block}
                key={block.strapi_component}
                locale={locale}
              />
            );
          default:
            break;
        }
      })}
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: strapiSetting(locale: { eq: $lang }) {
      Main {
        SiteDescription
        SiteShortTitle
        SiteTitle
        Logo {
          alternativeText
          localFile {
            extension
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Header {
        ButtonLinks {
          LinkIcon {
            alternativeText
            localFile {
              extension
              childrenImageSharp {
                gatsbyImageData
              }
            }
          }
          LinkText
          LinkUrl {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column1Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column2Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
      }
      Footer {
        Address {
          address {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        FooterMenu {
          Label
          Slug {
            internal {
              type
            }
            locale
            slug
          }
        }
        Newsletter {
          Title
          Subtitle
        }
        Mobile {
          Number
        }
        Email {
          Address
        }
      }
    }
    page: strapiHomepage(locale: { eq: $lang }) {
      internal {
        type
      }
      locale
      localizations {
        data {
          attributes {
            locale
          }
        }
      }
      PageTitle
      Meta {
        PageMetaTitle
        PageMetaShortTitle
        PageMetaDescription
        PageMetaImage {
          localFile {
            url
          }
          width
          height
          alternativeText
        }
      }
      Blocks {
        ... on STRAPI__COMPONENT_SECTIONS_SLIDES {
          strapi_component
          Slide {
            Description {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            Title {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            ButtonLabel
            ButtonSlug {
              slug
              locale
              internal {
                type
              }
            }
            MediaLarge {
              ext
              url
              alternativeText
              height
              width
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: { fit: CONTAIN }
                    quality: 100
                  )
                }
                url
              }
            }
            BackgroundColor
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_TITLE_AND_TWO_BOXES {
          id
          title {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          strapi_component
          BackgroundImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
          BackgroundPosition {
            Position
          }
          Box1Label
          Box1BackgroundColor
          Box1Slug {
            slug
            locale
            internal {
              type
            }
          }
          Box2Label
          Box2BackgroundColor
          Box2Slug {
            slug
            locale
            internal {
              type
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_TITLE_VIDEO_BG {
          strapi_component
          Title
          Subtitle
          VideoBg {
            url
          }
          VideoPoster {
            alternativeText
            localFile {
              extension
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_HEXAGONS {
          strapi_component
          SectionTitle
          HexTitle1
          HexDescription1
          HexLink1 {
            slug
            locale
            internal {
              type
            }
          }
          HexBackground1 {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          HexTitle2
          HexDescription2
          HexLink2 {
            slug
            locale
            internal {
              type
            }
          }
          HexBackground2 {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          HexTitle3
          HexDescription3
          HexLink3 {
            slug
            locale
            internal {
              type
            }
          }
          HexBackground3 {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ButtonLabel
          ButtonSlug {
            slug
            locale
            internal {
              type
            }
          }
          DefaultBackground {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_TRUSTED_PARTNER {
          strapi_component
          PartnersTitle
          PartnersLogos {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIALS {
          strapi_component
          Title
          VideoBackground {
            localFile {
              url
            }
          }
          VideoPoster {
            alternativeText
            localFile {
              extension
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_WAVES {
          strapi_component
          VideoBackground {
            url
          }
          VideoPoster {
            alternativeText
            localFile {
              extension
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Title
          ButtonLabel
          ButtonSlug {
            slug
            locale
            internal {
              type
            }
          }
          Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allTestimonials: allStrapiTestimonial(filter: { locale: { eq: $lang } }) {
      nodes {
        Description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default HomepageTemplate;
