import React from "react";
import { Helmet } from "react-helmet";

const SchemaWebsite = ({ title, description, image, url, siteUrl }) => {
  const imageWidth = image.dimensions ? image.dimensions.width : image.width;
  const imageHeight = image.dimensions ? image.dimensions.height : image.height;
  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "url": "${url}",
          "name": "${title}",
          "description": "${description}",
          "image": {
            "@type": "ImageObject",
            "url": "${image.url}",
            "description": "${image.alt ? `${image.alt}` : `Eventure logo`}",
            "width": "${imageWidth | 512}",
            "height": "${imageHeight | 512}"
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${siteUrl}"
          }
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaWebsite;
