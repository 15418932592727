import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import MetaSite from "./MetaSite"
import MetaSocial from "./MetaSocial"
import SchemaWebsite from "./SchemaWebsite"
import SchemaOrganization from "./SchemaOrganization"
import SchemaNewsArticle from "./SchemaNewsArticle"
import SchemaItemsList from "./SchemaItemsList"
import { basePathResolver } from "../../utilities/basePathResolver";
import SchemaLocalBusiness from "./SchemaLocalBusiness";
import SchemaService from "./SchemaService";

// SEO Data
// ----------------------------------------------------------------------------
// Pass the entire page object to automatically populate all fields.
//
// Use individual props (ie: "title", "description" etc) on each page to
// override this field from page object.
//
// Fallback values are the siteMetadata defined in config/site-config.js.
// ----------------------------------------------------------------------------

const Seo = ({
  page,
  itemsList,
  title,
  shortTitle,
  description,
  image,
  url,
  siteUrl,
  lang,
  logoUrl,
}) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          shortTitle
          description
          logoUrl
          siteUrl
          siteLang

          defaultImage
          defaultImageWidth
          defaultImageHeight
          defaultImageAlt

          BusinessAddress
          BusinessCity
          BusinessCountry
          BusinessEmail
          BusinessName
          BusinessPhoneNumber
          BusinessPostalCode
          BusinessRegion
        }
      }
    }
  `);

  //? The variables we need are below
  //* From Meta
  // seoTitle
  // seoDescription
  // seoImage
  // shortTitle
  //* From Page Data
  // pageUrl
  // lang
  // altLangs
  //* From siteMetadata or site Settings
  // siteUrl
  // name
  // alternateName
  // logo
  //* From Post** Data
  // authorAlternateName
  // authorLogo
  // authorName
  // authorUrl
  // datePublished
  // headline

  const fallback = config.site.siteMetadata;

  /**
   * @namespace
   * @property {object} defaults
   * @property meta.PageMetaTitle
   * @property meta.PageMetaDescription
   * @property meta.PageMetaImage
   * @property meta.PageMetaShortTitle
   */
  let meta =
    page?.internal?.type === "STRAPI_POST"
      ? {
          PageMetaTitle: page?.Title || null,
          PageMetaShortTitle: null,
          PageMetaDescription:
            page?.Description?.data?.childMarkdownRemark?.excerpt || null,
          PageMetaImage: {
            localFile: page?.FeaturedImage?.localFile || null,
            width: page?.FeaturedImage?.width || null,
            height: page?.FeaturedImage?.height || null,
            alternativeText: page?.FeaturedImage?.alternativeText || null,
          },
        }
      : page?.Meta || null;

  // Main page title
  let seoTitle = title || meta?.PageMetaTitle || page?.Title || fallback.title;

  const titleBrand = new RegExp(`${fallback.shortTitle}$`, "gm").test(seoTitle)
    ? ""
    : ` | ${fallback.shortTitle}`;

  seoTitle += titleBrand;

  // Short title
  const seoShortTitle =
    shortTitle || meta?.PageMetaShortTitle || fallback.shortTitle;

  // Page description
  let seoDescription =
    description ||
    meta?.PageMetaDescription ||
    page?.Description?.childMarkdownRemark?.excerpt ||
    fallback.description;

  // Page URL
  let seoUrl = url || (page && basePathResolver(page)) || fallback.siteUrl;

  seoUrl = new URL(seoUrl, fallback.siteUrl).href;

  // Site URL
  const seoSiteUrl = siteUrl || fallback.siteUrl;

  // Page language
  let seoLang = lang || page?.locale || fallback.siteLang;

  // Site Logo URL
  const seoLogoUrl = logoUrl || fallback.logoUrl;

  // Page Image
  // --------------------------------------------------------------------------
  // Must be an object which includes the keys "url", "alt", "dimensions".
  // "dimensions" must include the keys "height" and "width".
  let seoImage = {
    url:
      meta?.PageMetaImage?.localFile?.url?.replace(
        "http://strapi.thinkplus.dev:6002",
        "https://esg-landing-page.thinkplus.dev"
      ) || fallback.defaultImage,
    alt: meta?.PageMetaImage?.alternativeText || fallback.defaultImageAlt,
    dimensions: {
      height: meta?.PageMetaImage?.width || fallback.defaultImageHeight,
      width: meta?.PageMetaImage?.height || fallback.defaultImageWidth,
    },
  };

  return (
    <>
      <MetaSite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
        lang={seoLang}
        altLangs={page && page.alternate_languages}
      />
      <MetaSocial
        title={seoTitle}
        shortTitle={seoShortTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
      />
      <SchemaWebsite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
      />
      <SchemaOrganization
        name={fallback.shortTitle}
        alternateName={fallback.title}
        url={fallback.siteUrl}
        logo={seoLogoUrl}
      />
      {page && page?.internal?.type === "STRAPI_POST" && (
        <SchemaNewsArticle
          headline={seoTitle}
          image={seoImage}
          datePublished={page?.publishedAt}
          authorName={fallback.shortTitle}
          authorAlternateName={fallback.title}
          authorUrl={fallback.siteUrl}
          authorLogo={seoLogoUrl}
        />
      )}
      {page && page?.slug?.includes("contact") && (
        <SchemaLocalBusiness settings={fallback} seoUrl={seoUrl} />
      )}
      {itemsList && <SchemaItemsList items={itemsList} siteUrl={seoSiteUrl} />}
      <SchemaService />
    </>
  );
};

export default Seo
